import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';

// home
const Home = React.lazy(() => import('../pages/Home'));

// pages
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Subscription = React.lazy(() => import('../pages/Dashboard/Subscription'));
const Settings = React.lazy(() => import('../pages/other/account/Settings'));

const Contact = React.lazy(() => import('../pages/other/Contact'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Checks = React.lazy(() => import('../pages/Checks'));
const CheckPage = React.lazy(() => import('../pages/Checks/CheckPage'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const Login = React.lazy(() => import('../pages/auth/Login'));
const Authorize = React.lazy(() => import('../pages/auth/Authorize'));
const GettingStarted = React.lazy(() => import('../pages/Dashboard/GettingStarted'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'contact',
                    element: <LoadComponent component={Contact} />,
                },
                {
                    path: 'pricing',
                    element: <LoadComponent component={Pricing} />,
                },
                {
                    path: 'signup',
                    element: <LoadComponent component={SignUp} />,
                },
                {
                    path: 'login',
                    element: <LoadComponent component={Login} />,
                },
                {
                    path: 'auth/authorize',
                    element: <LoadComponent component={Authorize} />,
                },
            ],
        },
        {
            // protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} />,
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Dashboard} />,
                        },
                        {
                            path: 'subscription',
                            element: <LoadComponent component={Subscription} />,
                        },
                        {
                            path: 'getting-started',
                            element: <LoadComponent component={GettingStarted} />,
                        },
                        {
                            path: 'checks',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={Checks} />,
                                },
                                // {
                                //     path: 'new',
                                //     element: <LoadComponent component={NewTaskPage} />,
                                // },
                                {
                                    path: ':id',
                                    element: <LoadComponent component={CheckPage} />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'settings',
                    element: <LoadComponent component={Settings} />,
                },
            ],
        },
    ]);
};

export default AllRoutes;
