import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AppState, useAppStore, User } from '../wrapper';

type PrivateRouteProps = {
    roles?: string;
};

const loading = () => <div className=""></div>;

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ roles, ...rest }: PrivateRouteProps) => {
    const user: User = useAppStore((state: AppState) => state.user);

    // if (user && user.email === null) {
    //     // role not authorised so redirect to home page
    //     return <Navigate to={{ pathname: '/' }} />;
    // }
    if (!user || !user?.name) {
        return <div>Loading...</div>;
    }

    if(user && user?.name === 'Anonymous') {
        return <Navigate to={{ pathname: '/login' }} />;
    }

    return (
        <Suspense fallback={loading()}>
            <Outlet />
        </Suspense>
    );
};

export default PrivateRoute;
